$dark-text: #202023;
$paragraph: #999FAE;
$purple: #781EA7;
$peach: #F37F8F;
$peach-dark: #da4c60;
$red-violet: #CC117A;
$java: #20C8B8;
$denim: #1187C5;
$white: #ffffff;
$border: #999FAE;
$california: #FE9507;
$background-light: #ECF2F6;
$white: #ffffff;
$black: #000;
$shadow: #DBE5EB;