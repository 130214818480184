p {
    color: $paragraph;
    font-weight: 300;
    line-height: 30px;
    font-size: 18px;
    margin: 0;
    font-family: 'Poppins',
        sans-serif;
}

a {
    display: inline-block;
}