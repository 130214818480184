@import 'https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:ital,wght@0,300;0,500;0,700;0,900;1,400&display=swap';

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'DM Sans',
        sans-serif;
    font-family: 'Poppins',
        sans-serif;
    font-size: 16px;
    color: $dark-text;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;

    &.page-statistics {
        overflow: auto;
        position: initial;
    }
}



.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 30px;
    position: relative;
    z-index: 8;

    @media (max-width: 767px) {
        height: 50px;
        padding: 15px;
    }

    &__logo {}

    &__telephone {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        &__number {
            position: relative;
            font-family: 'Poppins',
                sans-serif;

            &::before {
                content: url(./../images/telephone-icon.svg);
                position: relative;
                left: -4px;
                top: 5px;
            }

            a {
                color: $dark-text;
                text-decoration: none;

            }
        }
    }
}

.slide01,
.slide02,
.slide03,
.slide04,
.slide05 {
    @media (max-width: 767px) {
        height: calc(100% - 145px);
        display: flex;
    }
}