.ball {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 9;
    pointer-events: none;
    top: 0;
    left: 0;

    @media (max-width: 767px) {
        display: none;
    }

    &__01 {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        left: 33vw;
        top: 10vh;
        display: block;
        background: $black;
        border-radius: 100%;
        margin: 0;
        background: transparent radial-gradient(closest-side at 28% 38%, #FFE2BA 0%, #FFB54D 49%, #FFAD3A 66%, #FF9500 100%) 0% 0% no-repeat padding-box;

        @media (max-width: 1300px) {
            left: 5vw;
            top: 26vh;
        }
    }

    &__02 {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        position: absolute;
        right: 9vw;
        top: 15vh;
        display: block;
        background: $black;
        margin: 0;
        background: transparent radial-gradient(closest-side at 28% 38%, #BE85E5 0%, #9253C5 49%, #7836B1 84%, #6924A5 100%) 0% 0% no-repeat padding-box;
    }

    &__03 {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        position: absolute;
        right: -20px;
        top: 68vh;
        display: block;
        background: black;
        margin: 0;
        background: transparent radial-gradient(closest-side at 28% 38%, #FA78CD 0%, #EB1AA3 37%, #D21180 72%, #C51172 100%) 0% 0% no-repeat padding-box;
    }

    &__04 {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        position: absolute;
        right: 37vw;
        top: 6vh;
        display: block;
        background: $black;
        margin: 0;
        background: radial-gradient(circle at 18px 18px, #00EBC4, #380031);
    }

    &__05 {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        position: absolute;
        left: 47vw;
        top: 36vh;
        display: block;
        background: $black;
        margin: 0;
        background: transparent radial-gradient(closest-side at 28% 38%, #78EEFA 0%, #1AB1EB 49%, #118CD2 94%, #1187C5 100%) 0% 0% no-repeat padding-box;

        @media (max-width: 1600px) {
            left: 42vw;
        }

        @media (max-width: 1300px) {
            left: 11vw;
            top: 66vh;
        }
    }
}


.staged {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 1300px) {
        display: none;
    }

    svg {
        position: absolute;
        bottom: 0;
        transform: scale(3);
        max-width: 100%;

        @media (max-width: 1600px) {
            transform: scale(2.3);
        }
    }
}




.slide01 .staged__circle {
    fill: $purple;
}

.slide02 .staged__circle {
    fill: $red-violet;
}

.slide03 .staged__circle {
    fill: $java;
}

.slide04 .staged__circle {
    fill: $denim;
}

.slide05 .staged__circle {
    fill: $california;
}

.slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    display: flex;

    @media (max-width: 767px) {
        position: initial;
    }
}



.slide__left,
.slide__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 1300px) {
    .slide__left {
        display: none;
    }
}

.slide__text {
    position: relative;
    width: 330px;
    left: -10%;

    @media (max-width: 1300px) {
        left: 0;
    }
}



.slide__number {
    height: 165px;
    margin-bottom: 20px;
    overflow: hidden;

    @media (max-width: 500px) {
        height: 90px;
        margin-bottom: 10px;
    }

    @media (max-width: 400px) {
        height: 65px;
    }

    .number {
        font-size: 190px;
        line-height: 190px;
        font-family: "DM Sans", sans-serif;
        color: #202023;
        font-weight: 700;

        @media (max-width: 500px) {
            font-size: 90px;
            line-height: 90px;
        }

        @media (max-width: 400px) {
            font-size: 65px;
            line-height: 65px;
        }
    }
}




.slide__title {
    overflow: hidden;
    margin-bottom: 25px;

    @media (max-width: 500px) {
        margin-bottom: 15px;
    }

    h1 {
        font-family: "DM Sans", sans-serif;
        font-size: 43px;
        line-height: 53px;
        color: #202023;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;

        @media (max-width: 500px) {
            font-size: 43px;
            line-height: 43px;
        }

        @media (max-width: 400px) {
            font-size: 33px;
            line-height: 33px;
        }
    }
}









.slide__desc {
    overflow: hidden;
}

.slide__buttons {
    width: 100%;
    padding-top: 30px;
    overflow: hidden;

    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        a {
            width: calc(50% - 8px);
            height: 70px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }

        .slide__start {
            background: $peach;
            color: $white;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $white;
                background: $peach-dark;
            }
        }

        .slide__learn {
            border: solid 1px $border;
            color: $border;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $peach-dark;
                border: solid 1px $peach-dark;
            }
        }
    }


}











.slide__upnext {
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 30px;
    height: 95px;
    display: flex;

    @media (max-width: 500px) {
        padding-right: 15px;
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        text-decoration: none;

        &:hover span.arrow-icon {
            background: $peach-dark;
        }
    }

    &__image {
        width: 100px;

        img {
            width: 125px;
            max-width: initial;
            position: relative;

            &.thumb-two {
                width: 76px;
            }

            &.thumb-three {
                top: -20px;
                width: 90px;
            }

            &.thumb-four {
                left: -30px;
                top: -30px;
            }

            &.thumb-five {
                left: -20px;
                top: -30px;
                transform: scale(0.8);
            }

            &.thumb-contact {
                left: -20px;
                transform: scale(0.8);
                top: -20px;
            }
        }
    }

    &__desc {
        color: $paragraph;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        text-transform: uppercase;

        @media (max-width: 400px) {
            font-size: 14px;
        }

        .teaser {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            span.number {
                font-weight: 700;
                color: $dark-text;
                margin-right: 5px;
            }

            span.arrow-icon {
                width: 27px;
                height: 27px;
                background: $peach;
                display: flex;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }

            span.arrow-icon.started {
                margin-left: 20px;
            }
        }
    }

}









.slide__businessman {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: -60px;

    @media (max-width: 1600px) {
        left: -150px;
    }

    @media (max-width: 1300px) {
        display: none;
    }

    &.second img {
        bottom: -242px;
        transform: scale(1.3);

        @media (max-width: 1600px) {
            transform: scale(1.1);
            bottom: -271px;
        }
    }

    &.third {
        bottom: -60px;

        img {
            left: 175px;
            bottom: 113px;
        }

        .figure__shadow {
            background: #20C8B8;

            img {
                mix-blend-mode: multiply;
            }

        }
    }

    &.fourth {
        left: 0;

        @media (max-width: 1600px) {
            left: -160px;
        }

        .figure {
            left: 6px;
            bottom: 113px;

            &__shadow {
                background: $denim;
                left: 45px;
                bottom: 70px;

                img {
                    mix-blend-mode: multiply;
                }
            }
        }
    }

    &.fiveth {
        bottom: -30px;

        @media (max-width: 1600px) {
            bottom: -60px;
        }

        img {
            left: 138px;
            bottom: 123px;
        }

        .figure__shadow {
            background: $california;
            left: 310px;
            bottom: 104px;

            img {
                mix-blend-mode: multiply;
            }
        }
    }
}


.slide__businessman .figure {
    position: absolute;
    z-index: 5;
    left: 213px;
    bottom: 93px;

    &__shadow {
        background: $purple;
        display: block;
        position: absolute;
        z-index: 1;
        left: 180px;
        bottom: 78px;

        img {
            mix-blend-mode: multiply;
        }
    }
}


.pagination {
    width: 50px;
    height: 250px;
    position: fixed;
    z-index: 9;
    top: 50%;
    right: 0;
    margin-top: -105px;
    overflow: hidden;

    @media (max-width: 767px) {
        display: none;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            list-style-type: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-bottom: 25px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }

            a.dot {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }
}




body {

    &.page-slide01 #slide01,
    &.page-slide02 #slide02,
    &.page-slide03 #slide03,
    &.page-slide04 #slide04,
    &.page-slide05 #slide05 {
        border: solid 1px $paragraph;
    }

    &.page-statistics .pagination {
        display: none;
    }
}



.signup {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 130px;

    @media (max-width: 500px) {
        padding-top: 70px;
    }

    &__title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;
        padding: 0 30px;

        h2 {
            font-size: 32px;
            margin: 0;
            max-width: 550px;
            text-align: center;
            font-weight: bold;
        }
    }

    &__intro {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        padding: 0 30px;

        p {
            max-width: 730px;
            text-align: center;
        }
    }

    .statistics {
        background: #ECF2F6;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 50px;

        &__container {
            width: 1140px;
            max-width: 1140px;
            padding: 50px 0 65px;
            display: flex;

            @media (max-width: 991px) {
                padding: 50px 0 35px;
            }

            @media (max-width: 830px) {
                flex-direction: column;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }

        .block {
            display: block;
            text-align: center;
            width: 33.333333%;
            padding: 0 55px;
            box-sizing: border-box;

            @media (max-width: 1200px) {
                padding: 0 30px;
            }

            @media (max-width: 830px) {
                margin-bottom: 50px;
                width: 100%;
            }

            &__icon {
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                background: $white;
                box-shadow: 5px 18px 20px $shadow;
                border-radius: 50%;
            }

            &__title {
                display: flex;
                flex: 0 0 100%;
                justify-content: center;
                margin-top: 20px;

                h3 {
                    text-align: center;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 700;
                    font-family: "Poppins", sans-serif;
                }
            }

            &__desc {
                display: flex;
                flex: 0 0 100%;
                justify-content: center;

                p {
                    text-align: center;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 300;
                    max-width: 350px;
                }
            }

        }

        .form {
            width: 100%;
            display: block;
            position: relative;
            display: flex;
            justify-content: center;

            &__item {
                position: relative;
                top: 35px;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                width: 500px;
                max-width: 500px;
                padding: 0 30px;

                .send {
                    background: $peach;
                    color: #ffffff;
                    flex: 0 0 100%;
                    max-width: 100%;
                    overflow: hidden;
                    height: 70px;
                    border-radius: 20px;
                    display: flex;
                    border: none;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background: $peach-dark;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .source {
        width: 100%;
        margin-top: 100px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 300;
        color: #999FAE;
        padding-bottom: 50px;

        a {
            font-size: 12px;
            text-align: center;
            color: #999FAE;
            width: 100%;
            flex: 0 0 100%;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: #202023;
            }
        }
    }
}